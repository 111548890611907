import axios from './axios'
import warning from '@/assets/logo_danger.png'
import avatar from '@/assets/loading.gif'

export default {
  commonPageSize: 10,
  imgUpLoadFileList: ['jpg', 'png', 'jpeg', 'bmp', 'gif'],
  excelUpLoadFileList: ['xlsx', 'xls'],
  PDFUpLoadFileList: ['pdf'],
  alertTips (object) {
    let DOM = document.createElement('div')
    if (object.type === 'success') {
      DOM.className = 'allTips success'
    } else {
      DOM.className = 'allTips error'
    }
    // let html = `<span>${object.title}</span>: <span>${object.content}</span>`
    let html = `<span>${object.content}</span>`
    DOM.innerHTML = html
    document.body.appendChild(DOM)
    setTimeout(() => {
      DOM.style.top = '40px'
    }, 10)
    DOM.style.right = '45%'
    // let tips = document.querySelectorAll('.allTips')
    // if (tips.length === 1) {
    //   setTimeout(() => {
    //     DOM.style.top = '40px'
    //   }, 10)
    //   DOM.style.right = '45%'
    // } else {
    //   DOM.style.top = parseInt(tips[tips.length - 2].style.top.replace('px', ''), 0) + (tips[tips.length - 2].clientHeight + 12) + 'px'
    // }
    setTimeout(() => {
      DOM.style.right = '45%'
      setTimeout(() => {
        DOM.style.top = '-10%'
        setTimeout(() => {
          document.body.removeChild(DOM)
        }, 500)
      }, object.stayTime * 1000)
    }, 10)
  },
  returnQuery (url, object) {
    if (object) {
      let html = ''
      for (let i in object) {
        html += '&' + i + '=' + encodeURIComponent(object[i])
      }
      html = html.substr(1, html.length - 1)
      return url + '?' + html
    }
  },
  uploadFile (file) {
    let postFile = new FormData()
    postFile.append('file', file)
    var p = new Promise((resolve) => {
      axios.post('/aqt/sys/file/uploadFile', postFile).then(msg => {
        resolve(msg)
      })
    })
    return p
  },
  uploadHtFile (file) {
    let postFile = new FormData()
    postFile.append('file', file)
    var p = new Promise((resolve) => {
      axios.post('/contract/upContract', postFile).then(msg => {
        resolve(msg.data)
      })
    })
    return p
  },
  upSupportFile (file, openPOId) {
    let postFile = new FormData()
    postFile.append('file', file)
    var p = new Promise((resolve) => {
      axios.post('/fundBill/import?customerId=' + openPOId, postFile).then(msg => {
        resolve(msg.data)
      })
    })
    return p
  },
  showTips (value, time) {
    let dom = document.createElement('div')
    dom.id = 'doubleConfirm'
    dom.classList.add('myRemind')
    dom.innerHTML = value
    document.body.appendChild(dom)
    setTimeout(() => {
      dom.style.opacity = 1
      setTimeout(() => {
        dom.style.opacity = 0
        setTimeout(() => {
          dom.style.opacity = 0
          document.body.removeChild(dom)
        }, 300)
      }, time || 2000)
    }, 10)
  },
  doubleComfirm (object) {
    let dom = document.createElement('div')
    dom.id = 'doubleConfirm'
    let imgtUrl = '<img src="' + warning + '"  width="15%"/>'
    let html = `<div class="newTips">
      <div class="cover"  style="opacity: 1"></div>
      <div id="newTipsBody2" class="newTipsBody">
        <ul style="border-top: 3px solid #1791FC;border-radius: 5px;">
          <li class="d_f" style="justify-content: space-between;align-items: center"><span class="font20">${window.localStorage.getItem('language') === 'en-US' ? 'Prompt' : '提示'}</span><i id="cancelButtom2" class="icon iconfont iconclose" style="font-size: 25px"></i></li>
          <li class="t_c font24">
          <p style="text-align: center;font-size: 80px;color: red" class="icon iconfont iconjinggao"></p>
<!--          ${imgtUrl}-->
<!--          <i class="icon iconfont icongantan m_r_10" style="font-size: 25px;color: #C73343"></i>-->
          <span>${object.content}</span>
          </li>
          <li class="t_r">
            <button id="cancelButtom1" class="btn btn_normal m_l_10 m_r_10" ><span>${window.localStorage.getItem('language') === 'en-US' ? 'NO' : '取消'}</span></button><button id="confirmButtom1" class="btn btn_success m_r_10 m_l_10" ><span>${window.localStorage.getItem('language') === 'en-US' ? 'Yes' : '确定'}</span></button>
          </li>
        </ul>
      </div>
    </div>`
    dom.innerHTML = html
    document.body.appendChild(dom)
    setTimeout(function () {
      document.getElementById('newTipsBody2').style.opacity = '1'
      document.getElementById('newTipsBody2').style.top = '-30px'
    }, 10)
    let confirmButtom1 = document.getElementById('confirmButtom1')
    if (confirmButtom1) {
      confirmButtom1.onclick = function (e) {
        object.successFun(e)
        document.getElementById('newTipsBody2').style.opacity = '0'
        document.getElementById('newTipsBody2').style.top = '-100px'
        setTimeout(function () {
          document.body.removeChild(document.getElementById('doubleConfirm'))
        }, 300)
      }
    }
    let cancelButtom1 = document.getElementById('cancelButtom1')
    if (cancelButtom1) {
      cancelButtom1.onclick = function (e) {
        object.errorFun(e)
        document.getElementById('newTipsBody2').style.opacity = '0'
        document.getElementById('newTipsBody2').style.top = '-100px'
        setTimeout(function () {
          document.body.removeChild(document.getElementById('doubleConfirm'))
        }, 300)
      }
    }
    let cancelButtom2 = document.getElementById('cancelButtom2')
    if (cancelButtom2) {
      cancelButtom2.onclick = function (e) {
        object.errorFun(e)
        document.getElementById('newTipsBody2').style.opacity = '0'
        document.getElementById('newTipsBody2').style.top = '-100px'
        setTimeout(function () {
          document.body.removeChild(document.getElementById('doubleConfirm'))
        }, 300)
      }
    }
  },
  // 性别
  returnGender (type) {
    switch (type) {
      case 'Female':
        return window.localStorage.getItem('language') === 'en-US' ? 'Female' : '女'
      case 'Male':
        return window.localStorage.getItem('language') === 'en-US' ? 'Male' : '男'
      case '':
        return '-'
    }
    return ''
  },
  // 时间格式
  returnDate (date, type) {
    if (date === null || date === '' || date === undefined) {
      return ''
    } else {
      let newDate = new Date(date)
      let year = newDate.getFullYear()
      let month = newDate.getMonth() + 1
      let Todate = newDate.getDate()
      let hour = newDate.getHours()
      let min = newDate.getMinutes()
      let sec = newDate.getSeconds()
      let zhYear = window.localStorage.getItem('language') === 'en-US' ? '/' : '年'
      let zhMouth = window.localStorage.getItem('language') === 'en-US' ? '/' : '月'
      let zhDate = window.localStorage.getItem('language') === 'en-US' ? ' ' : '日'
      month = month < 10 ? '0' + month : month
      Todate = Todate < 10 ? '0' + Todate : Todate
      hour = hour < 10 ? '0' + hour : hour
      min = min < 10 ? '0' + min : min
      sec = sec < 10 ? '0' + sec : sec
      if (type === 'YYMMDDHHMMSS') {
        return year + '/' + month + '/' + Todate + ' ' + hour + ':' + min + ':' + sec
      } else if (type === 'YYMMDD') {
        return year + '/' + month + '/' + Todate
      } else if (type === 'YYMMDDHHMM') {
        return year + '/' + month + '/' + Todate + ' ' + hour + ':' + min
      } else if (type === 'YYMMDDZH') {
        return year + zhYear + month + zhMouth + Todate + zhDate
      } else if (type === 'YYMMDDHHMMSSHeng') {
        return year + '-' + month + '-' + Todate + ' ' + hour + ':' + min + ':' + sec
      } else if (type === 'YYMMDDHeng') {
        return year + '-' + month + '-' + Todate
      } else if (type === 'YYMM') {
        return year + '/' + month
      }
    }
  },
  returnNewDate (date, type) {
    if (date === null || date === '' || date === undefined) {
      return ''
    } else {
      if (type === 'YYMMDD') {
        return date.split(' ')[0]
      } else {
        return date.split('.')[0].replace('T', ' ')
      }
    }
  },
  todayMonth: new Date().getFullYear() + '-' + (new Date().getMonth() + 1),
  todayDate: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate(),
  todaymin: ((new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours())) + ':' + ((new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes())),
  checkIfEmpty (data, list, value) {
    let content = '请检查必填项！'
    if (value) {
      content = value
    }
    for (let i in data) {
      if (list.indexOf(i) >= 0 && !data[i]) {
        this.alertTips({
          type: 'error',
          title: '提示',
          content: content,
          stayTime: 5
        })
        return true
      }
    }
    return false
  },
  returnDigit (type, name) {
    if (type) {
      if (name === 'phone') {
        let reg = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/
        if (!reg.test(type)) {
          this.alertTips({
            type: 'error',
            title: '错误:',
            content: '请输入正确的手机号',
            stayTime: 5
          })
          return true
        }
      } else if (name === 'Amount') {
        let freg = /^\d+(\.\d+)?$/
        if (!freg.test(type)) {
          this.alertTips({
            type: 'error',
            title: '错误',
            content: '请输入正确的金额',
            stayTime: 5
          })
          return true
        }
      } else if (name === 'password') {
        let pasd = /^(((?=.*[a-z])(?=.*[A-Z])(?=.*\d))|((?=.*[A-Z])(?=.*\d)(?=.*[$!#$()*+<=>?_{}~-]))|((?=.*[a-z])(?=.*\d)(?=.*[$!#$()*+<=>?_{}~-]))|((?=.*[a-z])(?=.*[A-Z])(?=.*[$!#$()*+<=>?_{}~-])))(?=.{10,})/
        if (!pasd.test(type)) {
          this.alertTips({
            type: 'error',
            title: '错误:',
            content: '请输入正确的密码',
            stayTime: 5
          })
          return true
        }
      }
    }
    return false
  },
  numFixed (num, fixedNum) {
    if (num !== null && num !== undefined) {
      let newFixedNum = fixedNum || 2
      if (fixedNum) {
        return (parseFloat(num).toFixed(newFixedNum) + '').replace(/\d{2,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
      } else {
        return (parseFloat(num).toFixed(newFixedNum) + '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
      }
    } else {
      return 0
    }
  },
  datafixBoolean (data, list) {
    for (let i in data) {
      if (list.indexOf(i) >= 0) {
        if (data[i] === 'true' || data[i] === true) {
          data[i] = true
        } else {
          data[i] = false
        }
      }
    }
  },
  datafixNumber (data, list, fixedNum) {
    for (let i in data) {
      if (list.indexOf(i) >= 0) {
        if (fixedNum) {
          data[i] = parseFloat(parseFloat(data[i]).toFixed(2))
        } else {
          data[i] = parseInt(data[i])
        }
      }
    }
  },
  returnStatus (type, name) {
    let intType = parseInt(type)
    if (name === 'status') {
      switch (intType) {
        case 1:
          return '申请中'
        case 2:
          return '已通过'
        case 3:
          return '已拒绝'
      }
    } else {
      switch (intType) {
        case 1:
          return 'allColor0E7BFB'
        case 2:
          return 'allColor81D533'
        case 3:
          return 'allColorFF5319'
      }
    }
    return ''
  },
  returnOrderStatus (type, name, order) {
    let intType = parseInt(type)
    if (order === '1') {
      if (name === 'status') {
        switch (intType) {
          case 2:
            return '已购买'
          case 3:
            return '已接单'
          case 4:
            return '已确定'
          case 5:
            return '已作废'
        }
      } else if (name === 'color') {
        switch (intType) {
          case 2:
            return 'allColor0E7BFB'
          case 3:
            return 'allColorFF5319'
          case 4:
            return 'allColor81D533'
          case 5:
            return 'allColor909CA1'
        }
      }
    } else if (order === '2') {
      if (name === 'status') {
        switch (intType) {
          case 2:
            return '已购买'
          case 3:
            return '已发货'
          case 4:
            return '已收货'
          case 5:
            return '已作废'
        }
      } else if (name === 'color') {
        switch (intType) {
          case 2:
            return 'allColor0E7BFB'
          case 3:
            return 'allColorFF5319'
          case 4:
            return 'allColor81D533'
          case 5:
            return 'allColor909CA1'
        }
      }
    }
    return ''
  },
  returnType (type) {
    switch (parseInt(type)) {
      case 0:
        return '未审批'
      case 1:
        return '审批通过'
      case 2:
        return '拒绝'
    }
    return ''
  },
  returnTypeColor (type) {
    switch (parseInt(type)) {
      case 0:
        return 'colorLightGreen'
      case 1:
        return 'colorBlue'
      case 2:
        return 'colorPink'
    }
    return ''
  },
  scrollToTop (type, name) {
    // type: idName/className
    let temp
    if (type === 'id') {
      if (document.getElementById(name)) {
        temp = document.getElementById(name)
        temp.scrollTop = 0
      }
    } else {
      if (document.getElementsByClassName(name).length) {
        temp = document.getElementsByClassName(name)
        temp.forEach(p => {
          p.scrollToTop = 0
        })
      }
    }
  },
  photoCompress (file, w, objDiv) {
    let ready = new FileReader()
    ready.readAsDataURL(file)
    ready.onload = function () {
      let re = this.result
      this.canvasDataURL(re, w, objDiv)
    }
  },
  canvasDataURL (path, obj, callback) {
    var img = new Image()
    img.src = path
    img.onload = function () {
      let that = this
      // 默认按比例压缩
      let w = that.width * 0.8
      let h = that.height * 0.8
      let scale = w / h
      w = obj.width || w
      h = obj.height || (w / scale)
      var quality = 0.7
      // 生成canvas
      var canvas = document.createElement('canvas')
      var ctx = canvas.getContext('2d')
      // 创建属性节点
      var anw = document.createAttribute('width')
      anw.nodeValue = w
      var anh = document.createAttribute('height')
      anh.nodeValue = h
      canvas.setAttributeNode(anw)
      canvas.setAttributeNode(anh)
      ctx.drawImage(that, 0, 0, w, h)
      // 图像质量
      if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
        quality = obj.quality
      }
      // quality值越小，所绘制出的图像越模糊
      var base64 = canvas.toDataURL('image/jpeg', quality)
      let newFile = this.dataURLtoFile(base64)
      // 回调函数返回base64的值
      callback(newFile)
    }
  },
  // 将base64编码转回file文件
  dataURLtoFile (dataurl) {
    let arr = dataurl.split(',')
    let mime = arr[0].match(/:(.*?);/)[1]
    let bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], '1.jpg', { type: mime })
  },
  coverGif () {
    if (!document.getElementById('loadingGif')) {
      let dom = document.createElement('div')
      dom.id = 'loadingGif'
      let html = '<img src="' + avatar + '" height="144" width="192"/>'
      dom.innerHTML = html
      document.getElementById('app').appendChild(dom)
    }
  },
  disCover () {
    if (document.getElementById('loadingGif')) {
      document.getElementById('app').removeChild(document.getElementById('loadingGif'))
    }
  }
}
