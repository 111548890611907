import { createRouter, createWebHashHistory } from 'vue-router'
import login from '@/components/pages/login'
import sitLogin from '@/components/pages/sitLogin'
import doctorList from '@/components/pages/doctorList'
import createdMeeting from '@/components/pages/createdMeeting'
import meetingList from '@/components/pages/meetingList'
import fakeLogin from '@/components/pages/fakeLogin'
import websocket from '@/components/pages/websocket'
import meetingDetail from '@/components/pages/meetingDetail'
const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/index',
    name: 'index',
    component: login
  },
  {
    path: '/login',
    name: 'login',
    component: sitLogin
  },
  {
    path: '/doctorList',
    name: 'doctorList',
    component: doctorList
  },
  {
    path: '/websocket',
    name: 'websocket',
    component: websocket
  },
  {
    path: '/createdMeeting',
    name: 'createdMeeting',
    component: createdMeeting
  },
  {
    path: '/meetingDetail',
    name: 'meetingDetail',
    component: meetingDetail
  },
  {
    path: '/meetingList',
    name: 'meetingList',
    component: meetingList
  },
  {
    path: '/fakeLogin',
    name: 'fakeLogin',
    component: fakeLogin
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
