<template>
  <div>
    <input type="text" class="allInput w90 m_t_b_30" v-model="username" placeholder="请输入用户id" >
    <button @click="fakeLogin" class="allBigButton">登录</button>
  </div>
</template>

<script>
import { defineComponent, toRefs, reactive, getCurrentInstance} from "vue"
import { useRouter } from "vue-router"
export default defineComponent( {
  name: 'HelloWorld',
  setup(props, ctx) {
    console.log(props)
    console.log(ctx)
    const router = useRouter()
    // const route = useRoute()
    const { proxy } = getCurrentInstance()
    console.log(router)
    console.log(proxy)
    const myData = reactive({
      username: '',
    })
    const fakeLogin = () => {
      proxy.$axios.post('/api/Auth/wecom/login/mock?id=' + myData.username + '&code=vXm[gItHckD%DYm').then(p => {
        console.log(p)
        sessionStorage.setItem('ByToken', p.data.token)
        localStorage.setItem('openId', p.data.openId)
        router.push('index')
      })
    }
    return {
      ...toRefs(myData),
      fakeLogin
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
