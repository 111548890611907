<template>
  <div>
    <img class="w100" src="../../assets/img/icon/indexbg1.png" />
    <div class="w100" style="background:#fff;border-radius: 0 30px 0 0;position: relative;top: -30px">
      <div class="split_3_m4 p_t_40">
        <div @click="todoctor" class="t_c">
          <div><img class="w60" src="../../assets/img/icon/yuyueBaifang.png" /></div>
          <div class="ft_16 f_w m_t_20">预约拜访</div>
        </div>
        <div @click="toonevone" class="t_c">
          <div><img class="w60" src="../../assets/img/icon/yiduiyiship.png" /></div>
          <div class="ft_16 f_w m_t_20">去拜访</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, toRefs, reactive, getCurrentInstance} from "vue"
import { useRouter } from "vue-router"
export default defineComponent( {
  name: 'HelloWorld',
  setup(props, ctx) {
    console.log(props)
    console.log(ctx)
    const router = useRouter()
    // const route = useRoute()
    const { proxy } = getCurrentInstance()
    console.log(router)
    console.log(proxy)
    const myData = reactive({
      username: '',
      password: ''
    })
    const todoctor = () => {
      router.push('doctorList')
    }
    const toonevone = () => {
      router.push('meetingList')
    }
    return {
      ...toRefs(myData),
      todoctor,
      toonevone
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
