import { createApp } from 'vue'
import App from './App.vue'
import multipleSelect from '@/components/myComponents/multipleSelect.vue'
import model from '@/components/myComponents/model.vue'
import page from '@/components/myComponents/page.vue'
import cander from '@/components/myComponents/cander.vue'
import canderYM from '@/components/myComponents/canderYearMonth.vue'
import newSelect from '@/components/myComponents/newSelect.vue'
import router from './router'
import commonJs from './assets/js/common'
import axios from './assets/js/axios'
import { Picker } from 'vant';
import { DatetimePicker } from 'vant';
import { Popup } from 'vant';
import 'vant/lib/index.css'
import './assets/css/common.css'
import './assets/css/iconfont.css'
const app = createApp(App)
app.use(DatetimePicker)
app.use(Picker)
app.use(Popup)
app.config.globalProperties.$commonJs = commonJs
app.config.globalProperties.$axios = axios
app.component('model', model)
app.component('page', page)
app.component('cander', cander)
app.component('canderYM', canderYM)
app.component('allSelect', multipleSelect)
app.component('newSelect', newSelect)
app.use(router).mount('#app')
