<template>
  <div>
    <div>
      <div class="d_f border_bottom p_10_20 d_f_s">
        <div class="required2">
          拜访日期
        </div>
        <div @click="pickTimeFun(0)">
          {{postData.date || '请选择时间'}} >
        </div>
      </div>
      <div class="d_f border_bottom p_10_20 d_f_s">
        <div class="required2">
          开始时间
        </div>
        <div @click="pickTimeFun(1)">
          {{postData.startTime || '请选择时间'}} >
        </div>
      </div>
      <div class="d_f border_bottom p_10_20 d_f_s">
        <div class="required2">
          结束时间
        </div>
        <div @click="pickTimeFun(2)">
          {{postData.endTime || '请选择时间'}} >
        </div>
      </div>
      <div class="d_f border_bottom p_10_20 d_f_s">
        <div class="required2">
          拜访目的
        </div>
        <div @click="choosePurp">
          {{postData.purpose || '请选择拜访目的'}} >
        </div>
      </div>
      <div v-if="postData.purpose === '其他'" class="border_bottom p_10">
        <div>
          <input v-model="otherPurpose" type="text" style="border: none;width: calc(100% - 20px)" class="allInput" maxlength="10" placeholder="选择【其他】时填写,请输入小于10个汉字" />
        </div>
      </div>
    </div>

    <div class="p_10 b_eee"></div>

    <div>
      <div class="d_f p_10_20 border_bottom d_f_s">
        <div>拜访客户</div>
      </div>
      <div class="p_10_20">
        <div class="split_3_7 m_t_b_10 border_all b_r_20 newListD">
          <div class="p_r">
<!--            <text v-if="docterData.certified" style="bottom: 10px;left:0;border-radius: 0 0 20px 0;background: #9AD650" class="ft_12 p_a statusTips c_fff">已认证</text>-->
<!--            <text v-if="!docterData.certified" style="bottom: 10px;left:0;border-radius: 0 0 20px 0;background: #ccc" class="ft_12 p_a statusTips c_fff">未认证</text>-->
            <img src="../../assets/img/yisheng.jpg" />
          </div>
          <div style="border-radius: 0 20px 20px 0;vertical-align: top;width: calc(100% - 100px)">
            <div class="split_8_2 p_10">
              <div>
                <div class="m_b_10 ft_16 f_w"><text class=" ft_18 f_w">{{docterData.name}}</text> <text v-if="docterData.state === 2" class="ft_16 m_l_r_10 statusTips b_EFF8E7 c_66B512">已完成</text></div>
                <div class="ft_14 m_b_5 c_666">{{docterData.department}}</div>
                <div class="ft_14 m_b_5 c_666">{{docterData.hospital}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="p_10 b_eee"></div>

    <div>
      <div @click="chooseDocu" class="d_f p_10_20 border_bottom d_f_s">
        <div class="required2">拜访资料</div>
        <div class="c_999">
          >
        </div>
      </div>
      <div v-if="chooseFileName" class="p_20">
        {{ chooseFileName }}
      </div>
    </div>

    <div class="b_eee">
      <div class="t_c m_t  p_t_40 p_b_40 w90">
        <div class="d_f d_f_m m_b_20">
          <div>
            <input v-model="postData.sendMessage" id="sendModel" type="checkbox" class="allCheckbox">
            <label for="sendModel"></label>
            <label for="sendModel">发送微信模板消息给被拜访者</label>
          </div>
        </div>
        <button @click="createdBaifang" class="allBigButton p_t_b_20">创建拜访</button>
      </div>
    </div>
    <van-popup position="bottom" v-model:show="showPicker[0]">
      <van-datetime-picker
          v-model="chooseTime"
          type="date"
          :min-date="minDate"
          @cancel="onCancel"
          @confirm="chooseTimeConfirm"
          title="选择年月日"
      />
    </van-popup>
    <van-popup position="bottom" v-model:show="showPicker[1]">
      <van-datetime-picker
          v-model="chooseTime"
          type="time"
          @cancel="onCancel"
          @confirm="chooseTimeConfirm"
          title="选择时分"
      />
    </van-popup>
    <van-popup position="bottom" v-model:show="showPicker[2]">
      <van-datetime-picker
          v-model="chooseTime"
          type="time"
          @cancel="onCancel"
          @confirm="chooseTimeConfirm"
          title="选择时分"
      />
    </van-popup>
    <van-popup position="bottom" v-model:show="showPurpose">
      <van-picker
          title="标题"
          @cancel="onCancel"
          :columns="purposeList"
          @confirm="conformPurpose"
      />
    </van-popup>
    <van-popup position="bottom" v-model:show="showFile">
      <van-picker
          title="标题"
          @cancel="onCancel"
          :columns="fileList"
          @confirm="conformfileList"
      />
    </van-popup>

    <div v-if="showSuccess" id="model">
      <div style="opacity: 1" class="cover"></div>
      <div class="allModel">
        <div style="position: relative;top: -70px" class="createdSuccess">
          <img class="w50" style="border-radius: 20px 0 0 20px" src="../../assets/img/createdSucess.png" />
          <div class="m_t_b_20 ft_20 c_blue">创建成功</div>
          <div class="m_t_b_20">您已创建成功，稍后可以在拜访计划中查看本次创建信息详情</div>
          <button @click="confirmC" class="allBigButton p_t_b_20">确认</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, toRefs, reactive, getCurrentInstance, onMounted} from "vue"
import { useRouter } from "vue-router"
export default defineComponent( {
  name: 'HelloWorld',
  setup(props, ctx) {
    console.log(props)
    console.log(ctx)
    const router = useRouter()
    // const route = useRoute()
    const { proxy } = getCurrentInstance()
    console.log(router)
    console.log(proxy)
    const myData = reactive({
      chooseTime: '',
      minDate: new Date(),
      docterData: {},
      chooseFileName: '',
      documentList: [],
      purposeList: ['介绍产品', '回访', '其他'],
      fileList: [],
      postData: {
        date: proxy.$commonJs.todayDate,
        startTime: '',
        endTime: '',
        purpose: '',
        doctorId: 0,
        fileId: 0,
        sendMessage: true
      },
      showPicker: [
          false,
          false,
          false
      ],
      showSuccess: false,
      showPurpose: false,
      showFile: false,
      chooseType: 0,
      otherPurpose: ''
    })
    const onCancel = () => {
      myData.showPicker = [
        false,
        false,
        false
      ]
      myData.showSuccess = false
      myData.showPurpose = false
      myData.showFile = false
    }
    const pickTimeFun = (type) => {
      if (type === 0) {
        myData.chooseTime = new Date()
      } else if (type === 1) {
        myData.chooseTime = myData.postData.startTime
      } else if (type === 2) {
        myData.chooseTime = myData.postData.endTime
      }
      myData.chooseType = type
      myData.showPicker[type] = true
    }
    const conformPurpose = (e) => {
      myData.postData.purpose = e
      myData.showPurpose = false
    }
    const chooseDocu = () => {
      myData.showFile = true
    }
    const conformfileList = (e, index) => {
      console.log(e)
      console.log(index)
      myData.showFile = false
      myData.postData.fileId = myData.documentList[index].documentId
      myData.chooseFileName = myData.documentList[index].title
    }
    const choosePurp = () => {
      myData.showPurpose = true
    }
    const chooseTimeConfirm = () => {
      if (myData.chooseType === 0) {
        myData.postData.date = proxy.$commonJs.returnDate(myData.chooseTime, 'YYMMDD')
      }
      if (myData.chooseType === 1) {
        // myData.postData.startTime = proxy.$commonJs.returnDate(myData.chooseTime, 'YYMMDDHHMMSSHeng')
        myData.postData.startTime = myData.chooseTime
      }
      if (myData.chooseType === 2) {
        // myData.postData.endTime = proxy.$commonJs.returnDate(myData.chooseTime, 'YYMMDDHHMMSSHeng')
        myData.postData.endTime = myData.chooseTime
      }
      myData.showPicker[myData.chooseType] = false
    }
    const confirmC = () => {
      router.go(-1)
    }
    const createdBaifang = () => {
      if (!myData.postData.date || !myData.postData.startTime || !myData.postData.endTime || !myData.postData.fileId || !myData.postData.purpose) {
        proxy.$commonJs.showTips('请检查必填项')
        return
      }
      proxy.$commonJs.doubleComfirm({
        content: '是否确定创建？',
        successFun: () => {
          const post = JSON.parse(JSON.stringify(myData.postData))
          if (post.purpose === '其他') {
            post.purpose = myData.otherPurpose
          }
          proxy.$axios.post('/api/Meeting', post).then(() => {
            myData.showSuccess = true
          })
        },
        errorFun: () => {
        }
      })
    }
    onMounted(() => {
      const newDate = new Date().getTime() + (30 * 60 * 1000)
      const newDate2 = new Date().getTime() + (60 * 60 * 1000)
      myData.postData.startTime = ((new Date(newDate).getHours() < 10 ? '0' + new Date(newDate).getHours() : new Date(newDate).getHours())) + ':' + ((new Date(newDate).getMinutes() < 10 ? '0' + new Date(newDate).getMinutes() : new Date(newDate).getMinutes()))
      myData.postData.endTime = ((new Date(newDate2).getHours() < 10 ? '0' + new Date(newDate2).getHours() : new Date(newDate2).getHours())) + ':' + ((new Date(newDate2).getMinutes() < 10 ? '0' + new Date(newDate2).getMinutes() : new Date(newDate2).getMinutes()))
      myData.docterData = JSON.parse(sessionStorage.getItem('docterData') || '{}')
      myData.postData.doctorId = myData.docterData.id
      proxy.$axios.get('/api/Document/document/list').then(p => {
        myData.documentList = p.data
        myData.fileList = p.data.map(s => s.title)
        console.log(myData.fileList)
      })
    })
    return {
      ...toRefs(myData),
      conformPurpose,
      conformfileList,
      onCancel,
      chooseDocu,
      confirmC,
      createdBaifang,
      chooseTimeConfirm,
      choosePurp,
      pickTimeFun
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
