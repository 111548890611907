import axios from 'axios'
import connom from './common'
import avatar from '@/assets/loading.gif'
axios.defaults.timeout = 200000 // 超时响应
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL; // 根目录地址
axios.defaults.withCredentials = true
axios.interceptors.request.use(
  config => {
    config.headers = {
      Authorization: 'Bearer ' + sessionStorage.getItem('ByToken')
    }
    if (config.url.indexOf('?') >= 0) {
      config.url = config.url + '&t=' + new Date().getTime()
    } else {
      config.url = config.url + '?t=' + new Date().getTime()
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
export default {
  get (url) {
    return new Promise((resolve, reject) => {
      coverGif()
      axios.get(url)
        .then(response => {
          disCover()
          if (response.data.isSuccess) {
            resolve(response.data)
          } else {
            if (response.data.code === 401 || response.data.code === 402 || response.data.code === 415 || response.data.code === 415) {
              connom.showTips(response.data.message)
              window.location.href = '//' + window.location.host
              return
            }
            connom.showTips(response.data.message)
          }
        })
        .catch(err => {
          disCover()
          if (err.response.status === 401 || err.response.status === 402) {
            window.location.href = '//' + window.location.host
            return
          }
          connom.showTips('服务器报错')
          reject(err)
        })
    })
  },
  getFile (url) {
    return new Promise((resolve, reject) => {
      coverGif()
      axios.get(url)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          disCover()
          if (err.response.status === 401 || err.response.status === 402) {
            window.location.href = '//' + window.location.host
            return
          }
          connom.showTips('服务器报错')
          reject(err)
        })
    })
  },
  post (url, data) {
    return new Promise((resolve, reject) => {
      coverGif()
      axios.post(url, data)
        .then(response => {
          disCover()
          if (response.data.isSuccess) {
            resolve(response.data)
          } else {
            if (response.data.code === 401 || response.data.code === 402 || response.data.code === 415) {
              connom.showTips(response.data.message)
              window.location.href = '//' + window.location.host
              return
            }
            connom.showTips(response.data.message)
          }
        })
        .catch(err => {
          disCover()
          if (err.response.status === 401 || err.response.status === 402) {
            window.location.href = '//' + window.location.host
            return
          }
          connom.showTips('服务器报错')
          reject(err)
        })
    })
  },
  put (url, data) {
    return new Promise((resolve, reject) => {
      coverGif()
      axios.put(url, data)
        .then(response => {
          disCover()
          if (response.data.isSuccess) {
            resolve(response.data)
          } else {
            if (response.data.code === 401 || response.data.code === 402 || response.data.code === 415) {
              connom.showTips(response.data.message)
              window.location.href = '//' + window.location.host
              return
            }
            connom.showTips(response.data.message)
          }
        })
        .catch(err => {
          disCover()
          if (err.response.status === 401 || err.response.status === 402) {
            window.location.href = '//' + window.location.host
            return
          }
          connom.showTips('服务器报错')
          reject(err)
        })
    })
  },
  del (url) {
    return new Promise((resolve, reject) => {
      coverGif()
      axios.delete(url)
        .then(response => {
          disCover()
          if (response.data.isSuccess) {
            resolve(response.data)
          } else {
            if (response.data.code === 401 || response.data.code === 402 || response.data.code === 415) {
              connom.showTips(response.data.message)
              window.location.href = '//' + window.location.host
              return
            }
            connom.showTips(response.data.message)
          }
        })
        .catch(err => {
          disCover()
          if (err.response.status === 401 || err.response.status === 402) {
            window.location.href = '//' + window.location.host
            return
          }
          connom.showTips('服务器报错')
          reject(err)
        })
    })
  },
  asyncGet (url) {
    var p = new Promise((resolve) => {
      axios.get(url).then(msg => {
        resolve(msg.data)
      }).catch(err => {
        if (err.response.status === 401 || err.response.status === 402) {
          window.location.href = '//' + window.location.host
        }
      })
    })
    console.log(p)
    return p
  },
  asyncPost (url, data) {
    var p = new Promise((resolve) => {
      axios.post(url, data).then(msg => {
        resolve(msg.data)
      })
    })
    return p
  }
}
function coverGif () {
  if (!document.getElementById('loadingGif')) {
    let dom = document.createElement('div')
    dom.id = 'loadingGif'
    let html = '<img src="' + avatar + '" height="144" width="192"/>'
    dom.innerHTML = html
    document.getElementById('app').appendChild(dom)
  }
}
function disCover () {
  if (document.getElementById('loadingGif')) {
    document.getElementById('app').removeChild(document.getElementById('loadingGif'))
  }
}
