<template>
  <div class="p_10">
    <div @click="goNext" class="newListD2 m_t_b_20 border_all b_r_20">
      <div class="p_r">
        <img src="../../assets/img/yisheng.jpg" />
      </div>
      <div style="border-radius: 0 20px 20px 0;vertical-align: top;width: calc(100% - 100px)">
        <div style="height: 100%;padding: 0 10px;align-items: center" class="d_f">
          <div style="width: 100%">
            <div class="m_b_10 ft_16 f_w">
              <text class=" ft_16 f_w">远程拜访会议</text>
              <span v-if="isEnd()" style="border-radius: 10px 0 10px 0;background: #eff8e4; color: #80b345;margin-left: 5px" class="ft_12 statusTips">已结束</span>
            </div>
            <div class="ft_12 m_b_5 c_666">拜访时间:{{meetingDetail.time}}</div>
            <div class="d_f ft_12  c_666">
              <div>拜访目的:{{meetingDetail.purpose}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <div v-if="role === 2">-->
<!--      <button @click="shareLinkFun" class="allBigButton t_c p_t_b_20">分享链接</button>-->
<!--      <input style="position: fixed;top: 9999px" id="shareLink" :value="shareLink" type="text">-->
<!--    </div>-->
  </div>
</template>

<script>
import {defineComponent, toRefs, reactive, getCurrentInstance, onMounted} from "vue"
import { useRouter, useRoute } from "vue-router"
export default defineComponent( {
  name: 'HelloWorld',
  setup(props, ctx) {
    console.log(props)
    console.log(ctx)
    const router = useRouter()
    const route = useRoute()
    const { proxy } = getCurrentInstance()
    console.log(router)
    console.log(proxy)
    const myData = reactive({
      username: '',
      role: '',
      shareLink: '',
      meetingDetail: {},
      password: ''
    })
    onMounted(() => {
      if (route.query.token || sessionStorage.getItem('ByToken')) {
        if (route.query.token) {
          sessionStorage.setItem('ByToken', route.query.token)
        }
        localStorage.setItem('meetingCode', route.query.meetingCode)
        localStorage.setItem('role', route.query.role)
        myData.role = +route.query.role
        const url = proxy.$commonJs.returnQuery('/api/Meeting/info', {meetingSn: route.query.meetingCode})
        proxy.$axios.get(url).then(p => {
          myData.meetingDetail = p.data
          localStorage.setItem('openId', myData.meetingDetail.profiles && myData.meetingDetail.profiles.userId || '')
          myData.shareLink = 'https://' + window.location.host + '/#/meetingDetail?meetingCode=' + route.query.meetingCode + '&role=1'
        })
      } else {
        window.location.href = process.env.VUE_APP_BASE_URL + '/api/Auth/wecom/redirect?returnurl=' + encodeURIComponent(window.location.href)
      }
      localStorage.removeItem('userInfo')
    })
    const isEnd = () => {
      if (myData.meetingDetail.time) {
        const endTime = myData.meetingDetail.time.split(' ')[0].replace(/-/g, '/') + ' ' + myData.meetingDetail.time.split('-')[myData.meetingDetail.time.split('-').length - 1] + ':00'
        if (new Date(endTime).getTime() > new Date().getTime()) {
          return false
        } else {
          return true
        }
      }
    }
    const goNext = () => {
      const endTime = myData.meetingDetail.time.split(' ')[0].replace(/-/g, '/') + ' ' + myData.meetingDetail.time.split('-')[myData.meetingDetail.time.split('-').length - 1] + ':00'
      console.log(endTime)
      if (new Date(endTime).getTime() > new Date().getTime()) {
        const userInfo = {
          avatarUrl: myData.meetingDetail.profiles && myData.meetingDetail.profiles.avatar.indexOf('http') >= 0 && myData.meetingDetail.profiles.avatar || require('../../assets/img/defaultImage.png'),
          userId: myData.meetingDetail.profiles && myData.meetingDetail.profiles.userId || '',
          appId: myData.meetingDetail.profiles && myData.meetingDetail.profiles.appId || '',
          userSig: myData.meetingDetail.profiles && myData.meetingDetail.profiles.userSig || '',
          roomId: myData.meetingDetail.profiles && myData.meetingDetail.profiles.roomId || '',
          nickName: myData.meetingDetail.profiles && myData.meetingDetail.profiles.name || (+route.query.role === 1 ? '医生' : '代表'),
        }
        localStorage.setItem('myInfo', JSON.stringify(userInfo))
        router.push({
          name: 'websocket',
          query: {
            role: route.query.role,
            roomId: myData.meetingDetail.profiles && myData.meetingDetail.profiles.roomId,
          }
        })
      }
    }
    const shareLinkFun = () => {
      document.getElementById('shareLink').select()
      document.execCommand('Copy')
      proxy.$commonJs.showTips('复制成功')
    }
    return {
      ...toRefs(myData),
      goNext,
      isEnd,
      shareLinkFun
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
