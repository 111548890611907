<template>
  <div>

    <div id="divHeight2" class="d_f_s b_12384f p_10 d_f">
      <div class="w20 ">
        <img class="w60" style="margin-left: 20%" src="../../assets/img/icon/logo.png" />
      </div>
      <div class="d_f p_r w80 d_f_s">
        <input @keydown.enter="keyWordSearch" v-model="postData.Keyword" type="text" class="b_fff b_r_10 w100 p_10" placeholder="请输入关键字搜索" />
        <div @click="keyWordSearch" class="p_a searchIcon b_8ad32a">
          <img class="w40" src="../../assets/img/icon/search.png" />
        </div>
      </div>
    </div>

    <div class="b_fff p_10">
      <div id="divHeight" class="d_f_s d_f">
        <div class="ft_16 f_w">拜访列表</div>
        <div style="padding: 3px;background:#E4F5FF;" class="d_f b_r_100 d_f_s">
          <div data-id="0" @click="changeType(0)" :class="postData.type === 0 ? 'active' : ''" class="tabSwitch ft_12">全部</div>
          <div data-id="1" @click="changeType(1)" :class="postData.type === 1 ? 'active' : ''" class="tabSwitch ft_12">本周</div>
          <div data-id="2" @click="changeType(2)" :class="postData.type === 2 ? 'active' : ''" class="tabSwitch ft_12">本月</div>
        </div>
      </div>
      <div @scroll="scrollTop" :style="{'height': 'calc(100vh - ' + (topHeight + 60) + 'px)'}" style="width: 100%;overflow: auto;padding-bottom: 20px;margin-top: 10px">
        <div v-for="(item, index) in list" :key="index" class="newListD2 border_all m_t_b_20 b_r_20">
          <div class="p_r">
            <img  src="../../assets/img/yisheng.jpg" />
          </div>
          <div style="border-radius: 0 20px 20px 0;vertical-align: top;width: calc(100% - 100px)" >
            <div style="height: 100%;padding: 0 10px;align-items: center" class="d_f">
              <div :style="{width: item.state === 1 ? '100%' : '80%'}">
                <div class="m_b_10  ft_16 f_w">
                  <span class=" ft_16 f_w">{{item.name}}</span>
                  <span v-if="item.state === 2" style="border-radius: 10px 0 10px 0;background: #eff8e4; color: #80b345;margin-left: 5px" class="ft_12 statusTips">已结束</span>
<!--                  <text v-if="item.state === 2" class="ft_12 m_l_r_10 statusTips b_EFF8E7 c_66B512">已完成</text>-->
                </div>
                <div class="ft_12 m_b_5 c_666">{{item.hospital}}</div>
                <div style="align-items: center" class="d_f ft_12  c_666">
                  <div class="p_r">
                    <img style="width: 16px" src="../../assets/img/icon/clock.png" />
                  </div>
                  <div class="m_l_r_10">{{item.time}}</div>
                </div>
              </div>
              <div v-if="item.state === 1">
                <img @click="enterRoom(item)" class="w80" src="../../assets/img/icon/goLive.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {defineComponent, toRefs, reactive, getCurrentInstance, onMounted} from "vue"
import { useRouter } from "vue-router"
export default defineComponent( {
  name: 'HelloWorld',
  setup(props, ctx) {
    console.log(props)
    console.log(ctx)
    const router = useRouter()
    // const route = useRoute()
    const { proxy } = getCurrentInstance()
    console.log(router)
    console.log(proxy)
    const myData = reactive({
      departmentList: [],
      list: [],
      postData: {
        Keyword: '',
        Department: '',
        type: 0,
        PageIndex: 1,
        PageSize: 10
      },
      totalPage: 0,
      topHeight: 0,
      time: '',
    })
    onMounted(() => {
      myData.topHeight += document.getElementById('divHeight').clientHeight
      myData.topHeight += document.getElementById('divHeight2').clientHeight
      proxy.$axios.get('/api/Doctor/department/list').then(p => {
        myData.departmentList = p.data
      })
      getListData(1)
    })
    const changeType = (type) => {
      myData.postData.type = type
      getListData(1)
    }
    const keyWordSearch = () => {
      getListData(1)
    }
    const changeDepart = (type) => {
      myData.postData.Department = type
      getListData(1)
    }
    const getListData = (page) => {
      const data = JSON.parse(JSON.stringify(myData.postData))
      data.PageIndex = page
      const url = proxy.$commonJs.returnQuery('/api/Meeting/list', data)
      proxy.$axios.get(url).then(p => {
        myData.postData.PageIndex = page
        myData.totalPage = Math.ceil(p.total / myData.postData.PageSize)
        if (page === 1) {
          myData.list = p.datas
        } else {
          myData.list = myData.list.concat(p.datas)
        }
      })
    }
    const scrollTop = (e) => {
      clearTimeout(myData.time)
      myData.time = setTimeout(() => {
        if (e.target.scrollTop > e.target.clientHeight - 100) {
          if (myData.totalPage > myData.postData.PageIndex) {
            getListData(myData.postData.PageIndex + 1)
          }
        }
      }, 500)
    }
    const enterRoom = (item) => {
      console.log(item)
      router.push({
        name: 'meetingDetail',
        query: {
          meetingCode: item.meetingSn,
          role: 2,
        }
      })
    }
    return {
      ...toRefs(myData),
      keyWordSearch,
      changeDepart,
      changeType,
      enterRoom,
      getListData,
      scrollTop,
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
